import style from "./Certificate.module.css";
import certificate3 from "./Screenshot 2022-12-07 at 00.13.51.png";
import certificate4 from "./Screenshot 2022-12-07 at 00.14.26.png";
import certificate5 from "./Screenshot 2022-12-07 at 00.15.37.png";
import certificate6 from "./Screenshot 2022-12-07 at 00.17.04.png";
import certificate7 from "./Screenshot 2022-12-07 at 00.50.32.png";
import certificate1 from "./Screenshot 2022-12-07 at 01.00.43.png";
import certificate2 from "./Screenshot 2022-12-07 at 00.12.27.png";
import certificate8 from "./Screenshot 2022-12-07 at 17.49.01.png";
import certificate9 from "./Screenshot 2022-12-07 at 17.50.31.png";
import certificate10 from "./Screenshot 2022-12-07 at 17.51.15.png";


export function Certificate(){
    return(
        <div className={style.certificateBlock}>
            <div className={style.certificateBlock1}>
                <img src={certificate3} alt="img" className={style.pngCertificate}/>
                <img src={certificate4} alt="img" className={style.pngCertificate}/>
            </div>
            <div className={style.certificateBlock2}>
                <img src={certificate5} alt="img" className={style.pngCertificate1}/>
                <img src={certificate6} alt="img" className={style.pngCertificate2}/>
                <img src={certificate7} alt="img" className={style.pngCertificate3}/>
            </div>
            <div className={style.certificateBlock3}>
                <img src={certificate1} alt="img" className={style.pngCertificate4}/>
                <img src={certificate2} alt="img" className={style.pngCertificate5}/>
            </div>
            <div className={style.certificateBlock3}>
                <img src={certificate8} alt="img" className={style.pngCertificate8}/>
                <img src={certificate9} alt="img" className={style.pngCertificate9}/>
                <img src={certificate10} alt="img" className={style.pngCertificate10}/>
            </div>

        </div>
    )
}