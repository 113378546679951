import style from "./Escort.module.css"
import escortPng from "./Illustration.png"


export function Escort() {
    return (
        <div className={style.escortBlock}>
            <div className={style.container}>
                <img src={escortPng} alt="img" className={style.escortPn}/>
                <div className={style.wrapperBlockText}>
                    <h1 className={style.title}>Бухгалтерское сопровождение компании</h1>
                    <div className={style.text}>
                        Бухгалтерское сопровождение и обслуживание ООО,
                        ИП и других видов собственности включает в себя такие составляющие:
                        <ul>
                            <li className={style.li}>анализ и экспертную оценку текущего состояния бухгалтерского учета, его грамотная и
                                прозрачная постановка/восстановление;
                            </li>
                            <li className={style.li}>разработка дальнейшей политики бухгалтерского и налогового учета, его регистрация и
                                интеграция с другими системами (управленческий и финансовый учет предприятия или
                                организации);
                            </li >
                            <li className={style.li}>финансовое планирование в краткосрочной и долгосрочной перспективе;</li>
                            <li className={style.li}>работа над подготовкой отчетностей (квартальной, годовой), их сдача, мероприятия по
                                оптимизации налоговых выплат.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}