import style from "./OtherServices.module.css"
import pngService from "./Illustr.png"

export function OtherServices(){
    return(
        <div className={style.otherServicesBlock}>
          <div className={style.container}>
              <div className={style.primaryTitle}>
                  И это еще не все,
              </div>
              <div className={style.secondaryTitle}>
                  обратитесь к нам по следующим вопросам:
              </div>
              <div className={style.styledText}>
                     <ul className={style.ul}>
                         <li className={style.li}>Экспресс-анализ учёта и базы</li>
                         <li className={style.li}>Помощь в оформлении декларации 3НДФЛ</li>
                         <li className={style.li}>Сопровождение бизнеса</li>
                         <li className={style.li}>Налоговый консалтинг</li>
                         <li className={style.li}>Возмещение ФСС</li>
                         <li className={style.li}>Возмещение НДС</li>
                         <li className={style.li}>Разблокировка расчетного счета</li>
                         <li className={style.li}>Нулевая отчетность для ООО</li>
                         <li className={style.li}>Ликвидационный баланс 2019 года</li>
                         <li className={style.li}>Постановка учета для нового бизнеса</li>
                         <li className={style.li}>Нулевая отчётность для ИП</li>
                     </ul>
                  <img className={style.pngService} src={pngService} alt="png"/>
              </div>


          </div>
        </div>
    )
}