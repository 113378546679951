import './App.css';
import {Header} from "./Components/Header/Header";
import {Company} from "./Components/Pages/Company/Company";
import {Contacts} from "./Components/Pages/Contacts/Contacts";
import {Content} from "./Components/Content/Content";
import {Help} from "./Components/Pages/Help/Help";
import {Escort} from "./Components/Pages/Escort/Escort";
import {Routines} from "./Components/Pages/Routine/Routines";
import {OtherServices} from "./Components/Pages/OtherServices/OtherServices";



function App() {
    return (
        <div className="App">
            <Header/>
            <Company/>
            <Content/>
            <Help/>
            <Escort/>
            <Routines/>
            <OtherServices/>
            <Contacts/>
        </div>
    );
}

export default App;
