import style from "./Routines.module.css"
import {Routine} from "./Routine";

export function Routines() {
    return (
        <div className={style.routinesBlock}>
            <div className={style.container}>
                <h1 className={style.title}>Возьмем на себя решение рутинных, но важных задач:</h1>
                <div className={style.wrapperBlock}>
                   <Routine title={"Бухучет"} description={"Под индивидуальный запрос и потребности клиента, в том числе Управленческий учет."}/>
                   <Routine title={"Налоги"} description={"Консультации и разработка налоговой политики. Гарантируем отсутствие вопросов со стороны ИФНС."}/>
                   <Routine title={"Отчетность"} description={"Наша отчетность пройдет все проверки, мы знаем, что и как проверяют налоговики."}/>
                   <Routine title={"Кадры"} description={"Создание безрисковой кадровой политики. Устраиваем в штат, начисляем зарплату, ведем кадровую документацию."}/>
                </div>
            </div>
        </div>
    )
}