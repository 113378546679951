import * as React from "react";
import style from "./Nav.module.css"
import {Link} from "react-scroll"

export function Nav() {
    return (
        <nav className={style.navBlock}>
            <Link
                className={style.nav}
                to={"company"}
                spy={true}
                smooth={true}
                offset={-400}
                duration={500}
            >О Компании</Link>
     <Link
                className={style.nav}
                to={"contacts"}
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
            >Контакты</Link>
        </nav>
    )
}