import style from "./Contacts.module.css"
import phone from "./icons8-call-64.png"
import location from "./icons8-visit-50.png"
import mail from "./icons8-mail-50.png"

export function Contacts(){
    return(
        <div className={style.contactBlock} id="contacts">
          <div className={style.container}>
           <div className={style.row}>
             <div className={style.item}>
                 <img src={phone} alt="phone" className={style.phonePng}/>
                 <div>
                     <a className={style.number}
                        href="tel:+7 (960) 981-01-11">+7 (960) 981-01-11</a>
                 </div>
             </div>
             <div className={style.item}>
                 <img src={location} alt="location" className={style.locationPng}/>
                 <div className={style.location}>г.Омск</div>
             </div>
             <div className={style.item}>
                 <img src={mail} alt="mail" className={style.mailPng}/>
                 <div>
                     <a className={style.mail} href="mailto:ipdavydenko.nadezhda@gmail.com">
                         ipdavydenko.nadezhda@gmail.com
                     </a>
                 </div>
             </div>
           </div>
          </div>
            <div className={style.pageContent}>
               <div className={style.container}>
                   <div className={style.title}>
                       Напишите нам
                   </div>
                   <form>
                       <div className={style.rowInputs}>
                           <div className={style.itemInput}>
                               <div className={style.formGroup}>
                                   <div className={style.formLabel}></div>
                                   <input type="text"
                                          className={style.styledInput}
                                          placeholder={"Ваше имя*"}
                                   />
                               </div>
                           </div>
                            <div className={style.itemInput}>
                               <div className={style.formGroup}>
                                   <div className={style.formLabel}></div>
                                   <input
                                       type="text"
                                       className={style.styledInput}
                                       placeholder={"Ваш E-mail*"}/>
                               </div>
                           </div>
                            <div className={style.itemInput}>
                               <div className={style.formGroup}>
                                   <div className={style.formLabel}></div>
                                   <input type="text"
                                          className={style.styledInput}
                                          placeholder={"Номер телефона*"}
                                   />
                               </div>
                           </div>
                       </div>
                       <div className={style.formGroupSecond}>
                      <div className={style.formLabelSecond}>
                      </div>
                           <textarea className={style.styledTextarea} name="feedback[message]" placeholder={"Ваше сообщение"}></textarea>
                       </div>
                       <button className={style.formBtn}>
                           Оставить заявку
                       </button>
                       <div className={style.formAgreement}>
                           Нажимая на кнопку, вы соглашаетесь с <a className={style.formAgreementA} href="">политикой конфиденциальности</a>
                       </div>
                   </form>
               </div>
            </div>
        </div>
    )
}