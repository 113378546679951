import style from "./Part.module.css"


export function Part(props) {
    return (
        <div className={style.part}>
            <div className={style.container}>
                <div className={style.content}>
                    <div className={style.wrapper}>
                        <img src={props.style} alt="img" className={style.icon}/>
                        <div className={style.name}>{props.title}</div>
                    </div>
                    <div className={style.description}>{props.description}</div>
                </div>
            </div>
        </div>
    )
}