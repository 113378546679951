import style from "./Routine.module.css"

export function Routine(props) {
    return (
                  <div className={style.item}>
                      <div className={style.titleItem}>{props.title}</div>
                      <div className={style.styledText}>{props.description}</div>
                  </div>

    )
}