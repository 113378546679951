import * as React from "react";
import {Nav} from "../Nav/Nav";
import style from "./Header.module.css"
import {Link} from "react-scroll";

 export function Header(){
    return(
        <header className={style.headerBlock}>
            <div>
                <div className={style.titleCompany}>
                    ИП Давыденко Надежда Николаевана
                </div>
            </div>
            <Nav/>
            <Link
                to={"content"}
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
            >  <button className={style.btn}>
                Заказать звонок
            </button>
            </Link>


            <div className={style.phone}>
                <a href="tel:+79609810111">
                    +7 (960)
                    <span> 981-01-11</span>
                </a>
                </div>
        </header>
    )
}