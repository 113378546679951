import style from "./Form.module.css"

export function Form(){
    return(
        <form className={style.formBlock}>
            <div className={style.formTitle}>
                Отправте данные и мы свяжемся с вами
            </div>
            <input type="text"
                   className={style.formInput}
                   placeholder={"Имя"}
                   name={"order[name}"}
                   tabIndex={"0"}
             />
            <input type="text"
                   className={style.formInput}
                   placeholder={"Номер телефона*"}
                   name={"order[phone}"}
                   tabIndex={"0"}
             />
            <input type="text"
                   className={style.formInput}
                   placeholder={"Email*"}
                   name={"order[Email}"}

             />
            <button className={style.formBtn}>
                Оставить заявку
            </button>
            <div className={style.formAgreement}>
              Нажимая на кнопку, вы соглашаетесь с
                <a className={style.formAgreementA} href=""> политикой конфиденциальности</a>
            </div>
        </form>
    )
}