import style from "./Company.module.css"
import jpgCompany from './Depositphotos.jpg'
import notePng from "./photo copy 2.png"
import {Certificate} from "./Certificate";
import * as React from "react";
export function Company() {
    return (
        <div className={style.companyBlock} id={"company"}>
                <img src={jpgCompany} alt="jpg" className={style.jpgCompany}/>
            <div className={style.nameMainCompany}>ДИСТАНЦИОННЫЕ УСЛУГИ ПО БУХГАЛТЕРИИ</div>
            <div className={style.nameCompany}>для ИП и ООО</div>
                <div className={style.titleRow}>
                    <div className={style.prefix}>
                        <div className={style.line}>
                        </div>
                    </div>
                </div>
                <div className={style.textCompany}>
                    <em>
                        Для каждой организации главной целью является уверенное и непрерывное развитие бизнеса,
                        сопровождаемое увеличением прибыли.
                        Чтобы достичь такого результата следует уделить особое внимание ведению бухгалтерии.
                    </em>
                </div>
                <img src={notePng} alt="png" className={style.pngNote}/>
                <div className={style.systemTextNote}>
                    <a href="">Система налогообложения УСН (Доходы)</a>
                </div>
                <Certificate/>
        </div>
    )
}