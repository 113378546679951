import imgContent from "./images/123.png"
import style from "./Content.module.css"
import {Form} from "../Form/Form";
import {NavLink} from "react-router-dom";
import * as React from "react";

export function Content() {
    return (
        <div className={style.contentBlock} id={"content"}>
            <Form/>
            <img src={imgContent} alt="img"/>
        </div>
    )
}
