import style from "./Help.module.css"
import {Part} from "./Part";
import iconCompose from "../iconsBux/Compose.png";
import iconBriefcase from "../iconsBux/Briefcase.png";
import iconDocuments from "../iconsBux/Documents.png";
import iconContract from "../iconsBux/Contract.png";
import iconTools from "../iconsBux/Tools.png";
import iconClock from "../iconsBux/Clock.png";
import iconKey from "../iconsBux/Key.png";
import iconServer from "../iconsBux/Server.png";
import iconGear from "../iconsBux/Gear.png";
import * as React from "react";
export function Help() {
    return (
        <div className={style.helpBlock}>
            <div className={style.title}>Чем мы можем Вам помочь:</div>
            <div className={style.partBlock}>
                <Part title={"Ведение бухгалтерии ИП"}
                      description={" Ведение налогового учета, оформление документации,\n" +
                          "сдача отчетности, расчет налогов, начисление зарплаты и многое другое."}
                      style={iconCompose}/>
                <Part title={"Ведение бухгалтерии ООО"}
                      description={" Всё чаще передается на аутсорсинг, это позволяет избежать некомпетентности сотрудников," +
                          " создание и поддержка системы учета." +
                          " А так же юридическая помощь."}
                      style={iconBriefcase}/>
                <Part title={"Сдача отчетности"}
                      description={" Во все контролирующие органы: ФНС, ПФР, ФСС, Росстат и многие другие."}
                      style={iconDocuments}/>
            </div>
            <div className={style.partBlock}>
                <Part title={"Нулевая отчетность"}
                      description={" Для компаний и индивидуальных предпринимателей с любым режимом налогообложения."}
                      style={iconContract}/>
                <Part title={"Восстановление учета"}
                      description={" Для юридических лиц и индивидуальных предпринимателей за любой период."}
                      style={iconTools}/>
                <Part title={"Налоговое планирование"}
                      description={" Включает в себя: налоговый учет, выбор учетной политики, возврат налогов и рассчет налоговой нагрузки."}
                      style={iconClock}/>
            </div>
            <div className={style.partBlock}>
                <Part title={"Регистрация ИП и ООО"}
                      description={" Подготовим необходимый набор документов и заявлений."}
                      style={iconKey}/>
                <Part title={"Внесение изменений в ЕГРЮЛ и ЕГРИП"}
                      description={" При смене директора, фамилии, паспорта, прописки и др."}
                      style={iconServer}/>
                <div className={style.part}>
                    <div className={style.container}>
                        <div className={style.content}>
                            <div className={style.wrapper}>
                                <img src={iconGear} alt="img" className={style.icon}/>
                                <div className={style.name}>Дополнительные услуги</div>
                            </div>
                            <div>
                                <ul className={style.description}>
                                    <li>оформление первичной документации</li>
                                    <li>внедрение управленческого учета</li>
                                    <li>инвентаризация и ревизия</li>
                                    <li>создание финансовой службы</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}